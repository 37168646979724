import { createContext } from 'react'
import * as Requests from 'eyecons-requests'

type GuestContextType = {
    authenticated: Requests.Authenticated|null
    authenticatedIsLoading: boolean
    liveCount: number
    mutateAuthenticated: () => void
}

export const GuestContext = createContext<GuestContextType>({
    authenticated: null,
    authenticatedIsLoading: true,
    liveCount: 0,
    mutateAuthenticated: () => {},
})
