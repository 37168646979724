var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  apis: () => apis,
  deleteRecord: () => deleteRecord,
  get: () => get,
  getUrl: () => getUrl,
  post: () => post,
  put: () => put,
  useAuthenticated: () => useAuthenticated,
  useChannels: () => useChannels,
  useChannelsHomepage: () => useChannelsHomepage,
  useChannelsUser: () => useChannelsUser,
  useEvents: () => useEvents,
  useEventsCount: () => useEventsCount,
  useSports: () => useSports,
  useVideos: () => useVideos
});
module.exports = __toCommonJS(src_exports);

// src/methods.ts
var import_axios = __toESM(require("axios"));
var headers = {
  "X-Requested-With": "XMLHttpRequest"
};
if (process.env.NEXT_PUBLIC_USER_TOKEN) {
  headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_USER_TOKEN}`;
}
var axios = import_axios.default.create({
  headers,
  withCredentials: true
});
var apis = {
  backend: process.env.NEXT_PUBLIC_BACKEND_URL,
  media: process.env.NEXT_PUBLIC_MEDIA_URL,
  auth: process.env.NEXT_PUBLIC_AUTH_URL,
  ecommerce: process.env.NEXT_PUBLIC_ECOMMERCE_URL
};
function getUrl(api, url, queryParams, isPublic) {
  const queryString = queryParams ? new URLSearchParams(queryParams).toString() : null;
  return `${apis[api]}${isPublic ? "/public" : ""}${url}${queryString ? `?${queryString}` : ""}`;
}
function form(params) {
  let formData = new FormData();
  return createForm(formData, params);
}
async function get(url, params) {
  try {
    let res = await axios.get(url, params);
    return await res.data;
  } catch (error) {
    return checkForErrors(error);
  }
}
async function post(url, body = null, setErrors = null) {
  try {
    return await axios.post(url, form(body));
  } catch (error) {
    return checkForErrors(error, setErrors);
  }
}
async function put(url, body = null, setErrors = null) {
  try {
    return await axios.post(url, form({ ...body, _method: "PUT" }), {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  } catch (error) {
    return checkForErrors(error, setErrors);
  }
}
async function deleteRecord(url, setErrors = null) {
  try {
    let res = await axios.delete(url);
    return await res.data;
  } catch (error) {
    return checkForErrors(error, setErrors);
  }
}
function checkForErrors(response, setErrors = null) {
  var _a;
  if (!((_a = response == null ? void 0 : response.response) == null ? void 0 : _a.data)) {
    return { errors: [{ message: "something went wrong" }] };
  }
  if (!setErrors) {
    return response.response.data;
  }
  if (response.response.data.message && !response.response.data.errors) {
    setErrors([response.response.data.message]);
    return { errors: response.message };
  }
  const errors = response.response.data.errors;
  setErrors(errors);
  return response.response.data;
}
var createForm = (formData, params, key = "") => {
  for (let i in params) {
    if (!params.hasOwnProperty(i)) {
      continue;
    }
    let formKey = key ? key + `[${i}]` : i;
    if (Array.isArray(params[i]) && !params[i].length) {
      formData.append(formKey, params[i]);
      continue;
    }
    if (params[i] !== null && (Array.isArray(params[i]) || typeof params[i] === "object") && !(params[i] instanceof File || params[i] instanceof Date)) {
      formData = createForm(formData, params[i], formKey);
      continue;
    }
    formData.append(formKey, params[i] === null ? "" : params[i]);
  }
  return formData;
};

// src/authenticated.ts
var import_immutable = __toESM(require("swr/immutable"));
function useAuthenticated() {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable.default)(
    getUrl("auth", `/user`),
    get
  );
  return {
    data: data == null ? void 0 : data.data,
    error,
    isLoading,
    mutate
  };
}

// src/channels.ts
var import_immutable2 = __toESM(require("swr/immutable"));
var import_react = require("react");
function useChannels(queryParams, isPublic, isLazyLoading) {
  const [channels, setChannels] = (0, import_react.useState)([]);
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable2.default)(
    getUrl("backend", `/channels`, queryParams, isPublic),
    get
  );
  (0, import_react.useEffect)(() => {
    if (isLazyLoading) {
      setChannels([...channels, ...(data == null ? void 0 : data.data) || []]);
    } else {
      setChannels((data == null ? void 0 : data.data) || []);
    }
  }, [data == null ? void 0 : data.data]);
  return {
    data: channels,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/channelsHomepage.ts
var import_immutable3 = __toESM(require("swr/immutable"));
var import_react2 = require("react");
function useChannelsHomepage(queryParams, isPublic, isLazyLoading) {
  const [channelsHomepage, setChannelsHomepage] = (0, import_react2.useState)([]);
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable3.default)(
    getUrl("backend", `/channels/homepage`, queryParams, isPublic),
    get
  );
  (0, import_react2.useEffect)(() => {
    if (isLazyLoading) {
      setChannelsHomepage([...channelsHomepage, ...(data == null ? void 0 : data.data) || []]);
    } else {
      setChannelsHomepage((data == null ? void 0 : data.data) || []);
    }
  }, [data == null ? void 0 : data.data]);
  return {
    data: channelsHomepage,
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/channelsUser.ts
var import_immutable4 = __toESM(require("swr/immutable"));
function useChannelsUser(queryParams) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable4.default)(
    getUrl("backend", `/user_channels`, queryParams),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    error,
    isLoading,
    mutate
  };
}

// src/events.ts
var import_immutable5 = __toESM(require("swr/immutable"));
function useEvents(queryParams, isPublic = false) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable5.default)(
    getUrl("media", `/events`, queryParams, isPublic),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/eventsCount.ts
var import_immutable6 = __toESM(require("swr/immutable"));
function useEventsCount(queryParams, isPublic = false) {
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable6.default)(
    getUrl("media", `/events/count`, queryParams, isPublic),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || { count: 0 },
    error,
    isLoading,
    mutate
  };
}

// src/sports.ts
var import_immutable7 = __toESM(require("swr/immutable"));
function useSports(queryParams, isPublic = false) {
  const queryString = new URLSearchParams(queryParams).toString();
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable7.default)(
    getUrl("backend", `/sports`, queryParams, isPublic),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}

// src/videos.ts
var import_immutable8 = __toESM(require("swr/immutable"));
function useVideos(queryParams, isPublic = false, isTrending = false) {
  const queryString = new URLSearchParams(queryParams).toString();
  const {
    data,
    error,
    isValidating: isLoading,
    mutate
  } = (0, import_immutable8.default)(
    getUrl("backend", `/videos${isTrending ? "/trending" : ""}`, queryParams, isPublic),
    get
  );
  return {
    data: (data == null ? void 0 : data.data) || [],
    meta: data == null ? void 0 : data.meta,
    error,
    isLoading,
    mutate
  };
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  apis,
  deleteRecord,
  get,
  getUrl,
  post,
  put,
  useAuthenticated,
  useChannels,
  useChannelsHomepage,
  useChannelsUser,
  useEvents,
  useEventsCount,
  useSports,
  useVideos
});
