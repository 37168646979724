import React from 'react'
import { GuestContext } from 'src/layouts'
import { Footer, Nav } from 'src/components'
import * as Requests from "eyecons-requests";

type GuestProps = {
    children: React.ReactNode
    options?: {
        waitForAuthenticated?: boolean
    }
}

export function Guest({ children, options }: GuestProps) {
    const liveCount = Requests.useEventsCount({
        published: true,
        with_live_videos: true,
    }, true)

    const authenticated = Requests.useAuthenticated()

    if (options?.waitForAuthenticated && authenticated.isLoading) {
        return null
    }

    return (
        <GuestContext.Provider
            value={{
                authenticated: authenticated.data,
                authenticatedIsLoading: authenticated.isLoading,
                liveCount: liveCount.data.count,
                mutateAuthenticated: authenticated.mutate,
            }}
        >
            <Nav />
            <main className={'pt-[60px]'}>{children}</main>
            <Footer />
        </GuestContext.Provider>
    )
}
